import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Seo from '../components/Seo'
import Share from '../components/Share'

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { themeStyles } from '../utils/theme'
import { rhythm } from '../utils/typography'
import colors from '../utils/colors'

const styles = {
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    '& a': {
      backgroundColor: colors.blue,
      borderRadius: '5%',
      color: colors.white,
      fontSize: rhythm(0.75),
      fontWeight: 'bold',
      margin: rhythm(0.5),
      marginTop: 0,
      padding: rhythm(0.5),
      textDecoration: 'none',
      '& svg': {
        fontWeight: 'normal',
        verticalAlign: 'middle',
        marginBottom: '2px',
      }
    },
  },
}

class BlogIndex extends React.Component {
  render() {
    const { pageContext } = get(this, 'props')
    const { previousPagePath, nextPagePath } = pageContext
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')

    let posts = get(this, 'props.data.allMarkdownRemark.edges') || []

    return (
      <Layout location={this.props.location}>
        <Seo
          title="All posts"
          keywords={keywords}
        />
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <div css={themeStyles.textPadding}>
          {previousPagePath ? null : <div>
            <p><b>We are <a href="/pages/about/">the McGees</a>. Hear us whisper!</b></p>

            <p>We are taking our nomadic house/pet sitting lifestyle international. Follow us as we travel and write about our experiences! We invite you to explore with us the <a href="/places">places</a> we've visited and the <a href="/animals">animals</a> we've cared for during our journey. And definitely check out the cool <a href="/map">3D map</a> of our past destinations.</p>
          </div>}

          <PostList posts={posts} />
          <div css={styles.pagination}>
            {previousPagePath ? <Link to={previousPagePath}><FaChevronLeft /> Newer Posts</Link> : null}
            {nextPagePath ? <Link to={nextPagePath}>Older Posts <FaChevronRight /></Link> : null}
          </div>
        </div>
        <Share slug="" title={siteTitle} excerpt={siteDescription} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: {slug: { regex: "/posts/"}}}
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            published
          }
        }
      }
    }
  }
`
